import { axios } from "@lib/axios";
import SongRequest from "./song_request";
import VinylStyle from "./vinyl_style";
import User from "./user";

import { db } from "../firebase";
import { ref, child, get } from "firebase/database";

function getParams(options) {
  if (!options) return;

  let params = [];
  Object.entries(options).forEach(([key, value]) =>
    params.push(`${key}=${value}`)
  );
  return params.join("&");
}

const methods = {
  searchSongs: async function (options = {}) {
    let results = [];
    try {

      const params = getParams(options);
      const {
        data,
      } = await axios.get(`/api/v1/searchsongs?${params}`);
      results = data.results;
    } catch (error) { }

    return results;

    // Old implementation; broken
    // const params = getParams(options);

    //     const {
    //       data: { results },
    //     } = await axios.get(`/api/v1/searchsongs?${params}`);
    //     return results;
  },
  checkUserExist: async function (username) {
    const {
      data: { results },
    } = await axios.get(`/api/v1/usernameexist?username=${username}`);
    return results.exists;
  },
  getUserInfo: async function (uid) {
    const {
      data: { results },
    } = await axios.get(`/api/v1/getusername?uid=${uid}`);
    return results?.username;
  },
  createProfile: async function (uid, username) {
    const {
      data: { success },
    } = await axios.post(`/api/v1/addnewuser`, {
      uid,
      username,
    });
    return success;
  },
  /**
   *
   * @param {*} receiver
   * @param {*} message
   * @param {*} song
   * @param {{
   *            disc: string,
   *            emoji: string,
   *        }} vinylStyle passing only the component's id
   * @returns
   */
  sendSong: async function (receiver, message, song, vinylStyle) {
    const {
      data: { success },
    } = await axios.post(`/api/v1/sendsong`, {
      recipient: receiver,
      message,
      song,
      vinylStyle,
    });
    return success;
  },
  queryInbox: async function (
    options = {
      filter: "all",
    }
  ) {
    const params = getParams(options);
    const {
      data: { results },
    } = await axios.get(`/api/v1/queryinbox?${params}`);

    for (let index = 0; index < results?.length ?? 0; index++) {
      results[index].vinyl_style = await VinylStyle.getVinylStyleDetails(
        results[index].vinyl_style
      );
    }

    return results;
  },
  playedInbox: async function (inboxId) {
    const {
      data: { success },
    } = await axios.post(`/api/v1/playsongfrominbox`, {
      inboxId,
    });
    return success;
  },
  getTotalSongSent: async function () {
    // const {
    //   data: { value },
    // } = await axios.get(`/api/v1/totalsongsent`);
    // return value;

    const dbRef = ref(db);
    const snapshot = await get(child(dbRef, `total_song_sent`));
    const value = snapshot.val() ?? 0;
    return value;
  },
  getTopChartSongs: async function () {
    const {
      data: { results },
    } = await axios.get(`/api/v1/topchartsongs`);

    return results;
  },
  getStreamsUrl: async function (id) {
    const {
      data: { results },
    } = await axios.get(`/api/v1/getsongstreams?id=${id}`);

    return results;
  },
};

const GimmesongAPI = { ...methods, SongRequest, User };

/**
 * temperary search function
 * @param {*} content
 * @returns
 */
const _pipedSearchResultParser = function (content) {
  const results = [];
  if (!content.items) return results;

  for (let index = 0; index < content.items.length; index++) {
    try {
      const item = content.items[index];
      const parsedItem = {
        title: item.title,
        artistInfo: {
          artist: [
            {
              browseId: item.uploaderUrl.substring(9),
              pageType: "MUSIC_PAGE_TYPE_ARTIST",
              text: item.uploaderName,
            },
          ],
        },
        videoId: item.url.substring(9),
        thumbnails: [
          {
            height: 120,
            width: 120,
            placeholder: item.thumbnail,
            url: item.thumbnail,
          },
        ],
        length: _formatDuration(item.duration),
      };
      results.push(parsedItem);
    } catch (error) { }
  }

  return results;
};

const _formatDuration = function (durationInSeconds) {
  // Ensure the input is a valid number
  if (typeof durationInSeconds !== "number" || isNaN(durationInSeconds)) {
    return "0:0";
  }

  // Calculate minutes and seconds
  const minutes = Math.floor(durationInSeconds / 60);
  const seconds = durationInSeconds % 60;

  // Format the result as a string with padding for seconds
  const formattedSeconds = String(seconds).padStart(2, "0");

  return `${minutes}:${formattedSeconds}`;
};

export default GimmesongAPI;
